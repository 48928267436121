<template>
  <v-app-bar :clipped-left="lgAndUp" elevate-on-scroll flat>
    <v-container>
      <v-row>
        <v-col md="auto" class="d-flex align-center">
          <v-app-bar-nav-icon v-if="!mdAndUp" @click.stop="drawer = !drawer" />
          <v-toolbar-title
            v-if="!mdAndUp"
            class="font-weight-bold text-h5 primary--text text-center"
            style="cursor: pointer"
            @click="$router.push('/')"
          >
            <img :src="IEBLogo" height="20" alt="IE Brewers logo" />
          </v-toolbar-title>
        </v-col>

        <v-col v-if="mdAndUp" align="center">
          <template v-for="(item, index) in menuItems" :key="index">
            <v-menu v-if="item.routes !== undefined">
              <template v-slot:activator="{ props }">
                <v-btn class="text-capitalize" v-bind="props">
                  {{ item.title }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(route, i) in item.routes"
                  :key="i"
                  :value="i"
                  :to="route.route"
                  :href="route.href"
                  :target="route.target"
                  class="text-capitalize"
                >
                  <v-list-item-title>{{ route.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              v-else
              :key="index"
              :to="item.route"
              :href="item.href"
              :target="item.target"
              class="text-capitalize"
              exact
            >
              {{ item.title }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>

  <v-navigation-drawer v-model="drawer" bottom temporary>
    <v-list nav class="ml-2">
      <template v-for="(item, i) in menuItems" :key="i">
        <v-list-subheader v-if="item.routes">
          {{ item.title }}
        </v-list-subheader>

        <template v-if="item.routes">
          <v-list-item
            v-for="(sub, s) in item.routes"
            :key="s"
            :value="sub.title"
            :to="sub.route"
            :href="sub.href"
            :target="sub.target"
            :title="sub.title"
            active-color="primary"
            class="ml-2"
          >
          </v-list-item>
        </template>

        <template v-if="item.route">
          <v-list-item
            :key="i"
            :value="item.title"
            :to="item.route"
            :href="item.href"
            :target="item.target"
            :title="item.title"
            active-color="primary"
          >
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useDisplay } from "vuetify";
export default {
  name: "NavigationBar",
  setup() {
    const { smAndUp, lgAndUp, mdAndUp } = useDisplay();
    return { smAndUp, lgAndUp, mdAndUp };
  },
  data: () => ({
    drawer: false,
    IEBLogo: require("@/assets/ieb-icon.svg"),
    menuItems: [
      {
        title: "Home",
        route: "/",
      },
      {
        title: "About",
        routes: [
          { title: "About Us", route: "/about" },
          { title: "Bylaws", route: "/bylaws" },
          { title: "Code of Conduct", route: "/codeofconduct" },
        ],
      },
      {
        title: "Styles of the Month",
        route: "/styles",
      },
      {
        title: "Competitions",
        routes: [
          {
            title: "So Cal Regional Homebrew Championship",
            href: "https://comp.iebrewers.org",
            target: "_competition",
          },
          { title: "IE Brewer of the Year", route: "/boty" },
        ],
      },
      {
        title: "Events",
        route: "/events",
      },
      /* {
        title: "Blog",
        route: "/blog/1",
      }, */
      {
        title: "Membership",
        route: "/membership",
      },
      {
        title: "Contact",
        route: "/contact",
      },
    ],
  }),
};
</script>
